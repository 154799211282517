import { Injectable } from '@angular/core';
import Pusher, { Channel } from 'pusher-js';
import { environment } from 'src/environments/environment.prod';
import { SocketResponse } from '../_interfaces/socketResponse';

@Injectable({
  providedIn: 'root',
})
export class PusherService {
  private pusher: Pusher;
  private channels: { [key: string]: Channel } = {};

  constructor() {
    this.pusher = new Pusher(environment.pusherKey, {
      cluster: 'eu',
    });
  }

  subscribeToDiscussionChannel(
    channelName: string,
    eventName: string,
    notifyUser: boolean,
    callback: (data: SocketResponse) => void
  ) {
    if (!this.channels[channelName]) {
      this.channels[channelName] = this.pusher.subscribe(channelName);
    }
    this.channels[channelName].bind(eventName, (data: SocketResponse) => {
      callback(data);

      if (notifyUser) {
        this.showNotification(data.title, data.message);
      }
    });
  }

  public showNotification(title: string, message: string) {
    if ('Notification' in window) {
      this.requestNotificationPermission()
        .then((permission) => {
          if (permission === 'granted') {
            this.displayNotification(title, message);
          } else {
            console.warn('Notification permission not granted');
          }
        })
        .catch((error) => {
          console.error('Error requesting notification permission:', error);
        });
    } else {
      console.warn('Notifications are not supported in this browser.');
    }
  }

  private requestNotificationPermission(): Promise<NotificationPermission> {
    if (Notification.permission === 'granted') {
      return Promise.resolve('granted');
    } else if (Notification.permission !== 'denied') {
      return Notification.requestPermission();
    } else {
      return Promise.resolve('denied');
    }
  }

  private displayNotification(title: string, message: string) {
    navigator.serviceWorker
      .getRegistration()
      .then((registration) => {
        if (registration) {
          registration.showNotification(title, {
            body: message,
            icon: 'assets/icons/icon-72x72.png',
            badge: 'assets/icons/icon-72x72.png',
          });
        } else {
          console.error('Service Worker registration not found');
        }
      })
      .catch((error) => {
        console.error('Error getting Service Worker registration:', error);
      });
  }
}
